export function init() {

	var $map = document.getElementById('map');

	window.callInitGoogleOptions = function() {
		if($('.section--map').attr('data-temp-dir')){
			initGoogleOptions();
		} else {
			initGoogleOptionsContact();
		}
	};

	var loadAsyncScript = function() {
		var s = document.createElement('script');

		s.src = '//maps.googleapis.com/maps/api/js?sensor=false&callback=callInitGoogleOptions&language=pl';
		document.body.appendChild(s);
	};

	$(window).bind('load scroll', function(){
		var mapCanvasOffset = $('#map').offset().top - $(window).scrollTop() - 1000;

		if( $(window).scrollTop() > mapCanvasOffset && !$('body').is('.map-loaded') ) {
			$(document).trigger('load-map');
		}
	});
	
	$(document).on('load-map', function(){
		$('body').addClass('map-loaded');
		
		loadAsyncScript();
	});

	function initGoogleOptions() {
		var is_768 = $(window).width() <= 768;

		var myLatLng = {lat: 51.910661, lng: 16.9639703},
			map = new google.maps.Map($map, {
	        	center: myLatLng,
	        	zoom: is_768 ? 5 : 6,
	        	scrollwheel: false,
	        	mapTypeControl: true,
			    mapTypeControlOptions: {
			        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
			        position: google.maps.ControlPosition.RIGHT_TOP
			    },
			    zoomControl: true,
			    zoomControlOptions: {
			        position: google.maps.ControlPosition.LEFT_TOP
			    },
			    scaleControl: true,
			    streetViewControl: true,
			    streetViewControlOptions: {
			        position: google.maps.ControlPosition.LEFT_TOP
			    },
	        	mapTypeId: google.maps.MapTypeId.ROADMAP,
	        	styles: [
				    {
				        "featureType": "landscape",
				        "stylers": [
				            {
				                "saturation": -100
				            },
				            {
				                "lightness": 65
				            },
				            {
				                "visibility": "on"
				            }
				        ]
				    },
				    {
				        "featureType": "poi",
				        "stylers": [
				            {
				                "saturation": -100
				            },
				            {
				                "lightness": 51
				            },
				            {
				                "visibility": "simplified"
				            }
				        ]
				    },
				    {
				        "featureType": "road.highway",
				        "stylers": [
				            {
				                "saturation": -100
				            },
				            {
				                "visibility": "simplified"
				            }
				        ]
				    },
				    {
				        "featureType": "road.arterial",
				        "stylers": [
				            {
				                "saturation": -100
				            },
				            {
				                "lightness": 30
				            },
				            {
				                "visibility": "on"
				            }
				        ]
				    },
				    {
				        "featureType": "road.local",
				        "stylers": [
				            {
				                "saturation": -100
				            },
				            {
				                "lightness": 40
				            },
				            {
				                "visibility": "on"
				            }
				        ]
				    },
				    {
				        "featureType": "transit",
				        "stylers": [
				            {
				                "saturation": -100
				            },
				            {
				                "visibility": "simplified"
				            }
				        ]
				    },
				    {
				        "featureType": "administrative.province",
				        "stylers": [
				            {
				                "visibility": "off"
				            }
				        ]
				    },
				    {
				        "featureType": "water",
				        "elementType": "labels",
				        "stylers": [
				            {
				                "visibility": "on"
				            },
				            {
				                "lightness": -25
				            },
				            {
				                "saturation": -100
				            }
				        ]
				    },
				    {
				        "featureType": "water",
				        "elementType": "geometry",
				        "stylers": [
				            {
				                "hue": "#ffff00"
				            },
				            {
				                "lightness": -25
				            },
				            {
				                "saturation": -97
				            }
				        ]
				    }
				]
	        });

	   	var infowindow = new google.maps.InfoWindow();
	   	var urlPath = $('.section--map').attr('data-temp-dir');
	   	$('.section--map').removeAttr('data-temp-dir');

	    $('[data-trader]').each(function(i){
	    	var $this = $(this),
	    		printAttr = $this.attr('data-trader-print'),
	    		flexoAttr = $this.attr('data-trader-flexo'),
	    		print,
	    		flexo,
	    		marker;

	    	if($this.attr('data-trader-packaging-content')){

	    		var contentPackaging = $this.attr('data-trader-packaging-content'),
	    			newContent = "<div class='content-gm content-gm--packaging'><div class='inn'>" + contentPackaging + "</div></div>";

	    	} else if($this.attr('data-trader-print-content')) {

	    		var contentPrint = $this.attr('data-trader-print-content'),
		    		newContent = "<div class='content-gm content-gm--print'><div class='inn'>" + contentPrint + "</div></div>";

	    	} else if($this.attr('data-trader-flexo-content')) {

	    		var contentFlexo = $this.attr('data-trader-flexo-content'),
	    			newContent = "<div class='content-gm content-gm--flexo'><div class='inn'>" + contentFlexo + "</div></div>";
	    	}

	    	if(typeof printAttr !== typeof undefined && printAttr !== false) {
	    		var print = true;
	    	} else if(typeof flexoAttr !== typeof undefined && flexoAttr !== false) {
	    		var flexo = true;
	    	}
			
			if(!$('body').hasClass('ie')) {
		    	marker = new google.maps.Marker({
			        position: new google.maps.LatLng($this.attr('data-map-geolat'), $this.attr('data-map-geolng')),
			        icon: (print === true) ? urlPath+'/img/marker2.svg' : (flexo === true) ? urlPath+'/img/marker3.svg' : urlPath+'/img/marker.svg',
			        map: map
			    });
			} else {
				marker = new google.maps.Marker({
			        position: new google.maps.LatLng($this.attr('data-map-geolat'), $this.attr('data-map-geolng')),
			        icon: (print === true) ? urlPath+'/img/marker2.png' : (flexo === true) ? urlPath+'/img/marker3.png' : urlPath+'/img/marker.png',
			        map: map
			    });
			}

		    google.maps.event.addListener(marker, 'click', (function(marker, i) {
		        return function() {
		          	infowindow.setContent(newContent);
		          	infowindow.open(map, marker);
		        }
		    })(marker, i));
	    });

	    google.maps.event.addListener(infowindow, 'domready', function() {

		    // Reference to the DIV that wraps the bottom of infowindow
		    var iwOuter = $('.gm-style-iw');

		    var iwBackground = iwOuter.prev();

		    // Removes background shadow DIV
		    iwBackground.children(':nth-child(2)').css({'display' : 'none'});

		    // Removes white background DIV
		    iwBackground.children(':nth-child(4)').css({'display' : 'none'});

		    // Moves the infowindow 115px to the right.
		    iwOuter.parent().parent().css({left: '180px', top:'40px'});
		    iwOuter.parent().addClass('infowindow-arrow');

		    // Delete arrow
		    iwBackground.css({'display' : 'none'});

		    // Reference to the div that groups the close button elements.
		    var iwCloseBtn = iwOuter.next();
		    iwCloseBtn.css({right: '50px', top: '15px'});

		    // If the content of infowindow not exceed the set maximum height, then the gradient is removed.
		    if($('.iw-content').height() < 140){
		    	$('.iw-bottom-gradient').css({display: 'none'});
		    }

		    // phone change on mobile
			if($(window).width() < 767) {
		        $('.phone').each(function() {
		            var ttdPhoneNumber = $(this).text();
		 
		            $(this).wrapInner('<a href=""></a>');
		            $(this).find('a').attr('href', 'tel:' + ttdPhoneNumber);
		        });
		    }
		});

	    google.maps.event.addListener(map, 'click', function() {
		    infowindow.close();
		});
		
	}

	function initGoogleOptionsContact() {
		var myLatLng = {lat: 52.1911112, lng: 20.9196285},
			map = new google.maps.Map($map, {
	        	center: myLatLng,
	        	zoom: 17,
	        	scrollwheel: false,
	        }),
	        marker = new google.maps.Marker({
		    	position: myLatLng
		  	});

	  	marker.setMap(map);
	}

}