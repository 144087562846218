import '../helpers/helpers';

import * as slickSliders from '../functions/slickSliders';
import * as googleMap from '../functions/googleMap';
import * as mobileNav from '../functions/mobileNav';
import * as homeTabs from '../functions/homeTabs';
import * as scrollEvent from '../eventHandlers/scrollEvent';
import {bindEvent} from '../eventHandlers/eventHandler';
import * as BrowserDetect from '../functions/browserDetect';

document.addEventListener('DOMContentLoaded', function(){

	$.fn.isAfter = function(sel) {
	    return this.prevAll(sel).length !== 0;
	}
	$.fn.isBefore = function(sel) {
	    return this.nextAll(sel).length !== 0;
	}

	var $fancybox = $('a.fancybox'),
		$map = document.getElementById('map'),
		$homeTabs = document.querySelector('.tabs-category'),
		$mobileFixedNav = document.querySelectorAll('.mobile-fixed-nav'),
		$mainSlider = document.querySelectorAll('.main-slider'),
		$odometer = document.querySelectorAll('.odometer');

	BrowserDetect.init();

	if($mainSlider){
		slickSliders.init();
	}

	if($fancybox.length) {
		$fancybox.fancybox({
			helpers: {
				overlay: {
					locked: false
				}
			}
		});
	}

	if($map) {
		googleMap.init();
	}

	if($mobileFixedNav) {
		mobileNav.init();
	}

	if($homeTabs) {
		homeTabs.init();
	}

	if($odometer) {
		scrollEvent.init();
	}

	$('.header').on('click', function(e){
		if($(this).hasClass('search-active') && e.target === this) {
			$(this).removeClass('search-active');
		}
	});

	$('.search-icon').on('click', function(){
		$('.header').addClass('search-active');
	});

	$('.header .close').on('click', function(){
		$('.header').removeClass('search-active');
	});

	if($('.sitemap').length) {
		$('ul').each(function() {
		    if($(this).isAfter("li")) {
		        $(this).prev().addClass('li-parent').addClass('li-has-list');
		        $(this).next().addClass('li-parent');
		        $(this).addClass('ul-child');

		        if($(this).next().is('li')) {
		        	$(this).next().next().addClass('li-parent');
		        }
		    }
		});

		$('.li-has-list').on('click', function(e){
			e.preventDefault();

			$(this).toggleClass('active').next().slideToggle();
		});
	}

	if($(window).width() >= 1024) {
		if($('.columns-hld--category-sec').length){
			$('.col-img').each(function(){
				var captionHeight = $(this).find('.caption').height();

				if(captionHeight > 19 && captionHeight <= 38) {
					$(this).find('.image-background').addClass('image-background--small')
				} else if(captionHeight > 38) {
					$(this).find('.image-background').addClass('image-background--smallest')
				}
			});
		}
	}

	if($('.category-col').length) {
		$('.category-mod-hld').on('click', '.btn-category-mod', function(){
			if($(this).hasClass('btn-category-mod--list')){
				$('.category-col--tree').fadeOut(function(){
					$('.category-col--list').fadeIn();
				});
			} else {
				$('.category-col--list').fadeOut(function(){
					$('.category-col--tree').fadeIn();
				});
			}

			$('.btn-category-mod').removeClass('active');
			$(this).addClass('active');
		});
	}

	$('.menu li:has(ul)').doubleTapToGo();
});

AOS.init({
	disable: 'mobile',
	easing: 'ease-in-out-sine',
	duration: 600
});