import {bindEvent} from '../eventHandlers/eventHandler';
var _throttle = require('lodash.throttle');
import * as initOdometer from '../functions/initOdometer';

export function init() {

	function scrollInit() {
		initOdometer.init();

		var scrollTop = $(this).scrollTop();

		if(scrollTop > 10) {
			$('.header').addClass('header--bordered');
		} else if(scrollTop <= 10) {
			$('.header').removeClass('header--bordered');
		}
	}

	bindEvent(window, 'scroll', _throttle(scrollInit, 300));

};