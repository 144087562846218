export function init() {

	window.onload=function() {
		(function(d) {
		 var ce=function(e,n){var a=document.createEvent("CustomEvent");a.initCustomEvent(n,true,true,e.target);e.target.dispatchEvent(a);a=null;return false},
		 nm=true,sp={x:0,y:0},ep={x:0,y:0},
		 touch={
		  touchstart:function(e){sp={x:e.touches[0].pageX,y:e.touches[0].pageY}},
		  touchmove:function(e){nm=false;ep={x:e.touches[0].pageX,y:e.touches[0].pageY}},
		  touchend:function(e){if(nm){ce(e,'fc')}else{var x=ep.x-sp.x,xr=Math.abs(x),y=ep.y-sp.y,yr=Math.abs(y);if(Math.max(xr,yr)>20){ce(e,(xr>yr?(x<0?'swl':'swr'):(y<0?'swu':'swd')))}};nm=true},
		  touchcancel:function(e){nm=false}
		 };
		 for(var a in touch){d.addEventListener(a,touch[a],false);}
		})(document);
	}

	var menuClass = '.js-mobile-trigger',
		hoverTxt = $(menuClass).find('.text').attr('data-hover'),
		normalTxt = $(menuClass).find('.text').attr('data-normal');

	var closeMenu = function() {
		$('body').removeClass('mobile-nav-active');
		$(menuClass).find('.text').text(normalTxt);
	};

	$(menuClass).on('click', function() {
		$('body').toggleClass('mobile-nav-active');

		if ($('body').hasClass('mobile-nav-active')) {
			$(this).find('.text').text(hoverTxt);
		} else {
			$(this).find('.text').text(normalTxt);
		}
	});

	$('.js-shadow-background').on('click', function() {
		closeMenu();
	});

	$('.header-nav-hld--mobile').on('click', '.menu-item-has-children', function(){
		$('.menu-item-has-children').removeClass('js-slideDown');

		$(this).addClass('js-slideDown');
	});

	document.body.addEventListener('swr', closeMenu, false);

}