export function init() {

	var $mainSlider = document.querySelector('.main-slider');

	if($mainSlider) {
		$('.main-slider').slick({
			infinite: true,
			speed: 1000,
			slidesToShow: 1,
			autoplay: true,
			fade: true,
			useTransform: true,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			dots: true,
			lazyLoad: 'ondemand',
			arrows: false,
			appendDots: document.querySelector('.dots-hld')
		});
	}

}