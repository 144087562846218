export function init() {
	var $tabsCategory = $('.tabs-category'),
		$tab = $('.tab'),
		$cat = $('.cat');

	$tabsCategory.on('click', '.cat', function(){
		var $self = $(this),
			categoryData = $self.attr('data-category-num');

		$('.tabs-category').find('.cat').removeClass('active');

		$self.addClass('active');

		$('.tabs-hld .tab:visible').stop(true, true).fadeOut(function(){
			$('[data-num="'+categoryData+'"]').stop(true, true).fadeIn();
		});
	});

	$cat.eq(0).addClass('active');
	$tab.eq(0).fadeIn();
}